html {
  scroll-behavior: smooth;
}

body{
    text-align: center;
    background-color: #EFEFEF; /*white theme*/
    font-size:1rem;
    hyphens: auto;
    font-family: 'Raleway', sans-serif;
}

h1, h3{
    font-family: 'Playfair Display', serif;
    font-size:2rem;
}

h6{
    color: rgba(60, 60, 60, 0.5);
    margin:0 0 0 0;
    font-size:1rem;
}

.p{
    font-family: 'Raleway', sans-serif;
    font-size:1rem;
}


/************************************ HEADER ***************************************/
.header-bar{
    border-width: 0;
    border-bottom-width: 2px;
    border-color: black;
    border-style: solid;
    font-family: 'Playfair Display', serif;
}

.header-container{
    max-width: 100%;
    width:90%
}

.nav-link-text, #basic-nav-dropdown{
    color: black;
    font-weight: 300;
    font-size: 1.3rem;
}

/* .logo-image */
@media (min-width: 1200px) {
    .logo-image{
        height: 4.5rem;
    }
}
@media (max-width: 1199px) {
    .logo-image{
        height: 2.5rem;
    }
}


.logo-subtitle{
    text-align: center;
}


/******************************** Page Container (excludes header, footer) ********************************/
.page-container{
    min-height: 100vh;
    background-color: #EBEBEB;
    background-image: url("../public/images/Resources/Repeating-Knots-grey-small.png");
    color: black;
}


/********************************* SECTIONS **************************************/
.white-section{
    /*background-color: #424242;*/
    background-image: url("../public/images/Resources/concrete-wall.png");
    color: black;
}

/* .section-formatting */
@media (min-width: 1200px) {
    .section-formatting{
        padding: 12rem 3% 7%;
    }
}
@media (max-width: 1199px) {
    .section-formatting{
        padding: 10rem 3% 7%;
    }
}
@media (max-width: 800px) {
    .section-formatting{
        padding: 8rem 3% 7%;
    }
}


/************************************ CAROUSEL **************************************/

.carousel-section{
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.carousel-image{
    max-height: calc(100vh - 18rem);
    max-width:100%;
    z-index: 1;
    border-radius: 30px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    margin-bottom: 1rem;
}

.carousel-text{
    color: rgba(200, 200, 200, 100);
}


/************************************ ABOUT-US **************************************/
.about-us-text{
    width:90%;
    display: inline-block;
    vertical-align: middle;
    font-family: 'Raleway', sans-serif;
}

@media (max-width: 1199px) {
    .about-us-text{
        width:90%;
        padding-left:0;
        padding-right:0;
        text-align: center;
    }
    .about-us-row{
        text-align: center;
    }
}

.about-us-image{
    max-height:450px;
    max-width:90%;
    border-radius: 100%;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    box-shadow:10px 10px 5px #101010;
    margin-left: 5%;
    margin-right: 5%;
    display: inline-block;
    vertical-align: middle;
}

/* styling for desk top viewports only */
@media (min-width: 1200px) {
    .about-us-left{
        text-align: right;
    }

    .about-us-right{
        text-align:left;
    }
}

.about-us-row{
    padding:25px 5px;
    display: inline;
    vertical-align: middle;
}

.about-us-title{
    margin-bottom: 10px;
}


/************************************ SERVICES **************************************/
.services-box{
    border-radius: 10px;
    background-color: #EFEFEF; /* white theme */
}

.service-tile-shell{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color:rgba(0, 0, 0, 0);
    border-style: none;
}

.service-image{
    max-width:100%;
    max-height:50%;
}

.service-image-container{
    max-height:200px;
    min-height:200px;
    max-height: 100%;
}

.service-title{
    margin-bottom: 1rem;
    word-wrap:break-word;
    max-width:100%;
    text-align: center;
    min-height: 4rem;
}

.service-description{
    max-width:100%;
    font-family: 'Raleway', sans-serif;
    text-align: center;
}


/************************************ CONTACT **************************************/
.contact-section{
    text-align:center;
    padding-bottom:10%;
}

.contact-us-container{
    position:relative;
    display: inline-block;
}



@media (max-width: 576px) {
    /* this styling only needs to be activated when the screen is in vertical cell phone diaplay mode */
    .contact-us-text{
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: 30px; /* this should exactly match the radius of the parent contact image */
    }
}

@media (min-width: 577px) {
    /* this styling only needs to be activated when the screen is NOT in vertical cell phone diaplay mode */
    .contact-us-text{
        min-width: 350px;
        max-width: 100%;
        border-radius: 5px;
    }
}

.contact-us-text{
    background-color: rgba(0, 0, 0, 0.25);
    color: rgba(230, 230, 230, 100);
    text-align: center;
    z-index: 1;
    padding:1%;
    max-height: 100%;
    position: absolute;
    top: 50%;  /* position the top left corner of the element at the center of the parent */
    left:50%;
    transform: translate(-50%, -50%); /* shift by 50% of the element's own height and width */
    font-family: 'Raleway', sans-serif;
}

.contact-us-text > .p{
    font-size:1.5rem;
    font-weight: 500;
}
.contact-us-text > h1{
    font-size:3rem;
    font-weight: 600;
}

.contact-us-image{
    max-height: calc(100vh - 9rem);
    max-width:100%;
    z-index: 1;
    border-radius: 30px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
}


/************************************* FOOTER **************************************/
footer{
    font-size: 0.9rem;
    padding: 20px 0;
    min-height: 50px;
    min-width: 100%;
    margin:0 0 0 0;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    border-top: 1px solid black;
}


/************************************* GALLERY **************************************/
.gallery-title{
    padding:1.5rem;
    text-decoration: underline;
}

.gallery-card{
    max-width: 500px;
}

.gallery-card-image{
    max-width:100%;
    max-height: 100%;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid black ;
}

.screen-overlay{
    position:fixed;
    background-color: rgba(0, 0, 0, 0.75);
    height: 100%;
    width:100%;
    z-index: 1000000;
    display:none;
    top: 0;
    left: 0;
    text-align: center;
    padding: 2rem 0.3rem;
}

.overlay-image-container{
    height:100%;
    width:100%;
    object-fit: contain;
}

.overlay-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.fullscreen-caption{
    color:rgba(255, 255, 255, 0.95);
    font-weight:bolder;
    padding: 0.2rem;
}


/************************************* GENERICS **************************************/
.text-card-formatting{
    padding:5%;
}

.drop-shadows{
    box-shadow:6px 6px 10px #000000;
}

.red-card-border{
    border-radius: 10px;
    border-style:solid;
    border-width: 5px;
    border-color: #D2232A; /* red theme */
    border-color: red;
    height: 100%;
    width: 100%;
}

.separator{
    border-top-style: dotted;
    border-top-color: #D2232A; /* red theme */
    border-top-width:0.5rem;
    width: 15%;
    margin: 5rem auto;
    margin-bottom: 0;
    color: rgb(0, 0, 0, 0);
    opacity: 100%;
}

.text-bare{
    font-weight:500;
}

.subtle-text{
    font-size: 0.8rem;
    color:rgb(100, 100, 100);
}


/************************************* OVERRIDES FOR IMPORTED COMPONENT CSS **************************************/


#css-overrides .container{
    /* header bar */
    max-width: 90%;
}


#css-overrides .dropdown-menu{
    /* this governs the width of the main menu drop-down box for the Galleries item */
    max-width: 50%;
    min-width: 13rem;
}

@media (max-width: 991px) {
    /* this styling only needs to be activated when the screen is narrow enough for the header menu to be collapsed into hamburger mode */
    #css-overrides .navbar-collapse{
        /* determines where the items in the hamburger menu are rendered */
        text-align: right;
    }

    #css-overrides .dropdown-menu{
        /* determines alignment of thje options inside the "gallery" dropdown box */
        text-align: right;
        min-width: 13rem;
    }

    #css-overrides .dropdown-menu.show{
        /* determines where the Gallery drop down list box renders */
        display:  inline-block;
    }
}
